<script setup lang="ts">
import { MessageEle } from '~/plugins/message.client'
import { hidePhoneNumber } from '~/utils/utils'
import { PersonalApi } from '~/net/apis/personal_api'

const onClickLeft = () => history.back()
const name = ref('')
const isVip = ref(true)
const showLoad = ref(false)
const phone = ref('')
const account = ref('')
const router = useRouter()
const userStore = useUser()

async function save() {
  console.log('保存', name.value)
  if (!name.value) {
    MessageEle.error('昵称不能为空')
    return
  }
  showLoad.value = true
  const data = await PersonalApi.updateInfo({ nickname: name.value })
  console.log(data)
  showLoad.value = false
  MessageEle.success('昵称更新完成')
}
phone.value = userStore.userInfo.mobile.split('-').pop() || ''
name.value = userStore.userInfo.nickname || ''
function bindPhone() {
  router.push('/bindPhone')
}
function changeAcount() {
  router.push('/changeAcount')
}

console.log('userStore', userStore.userInfo.userName)
</script>

<template>
  <div h-screen>
    <van-nav-bar title="我的" left-arrow @click-left="onClickLeft">
      <template #right>
        <button mr-2 ct-ffffff @click="save">保存</button>
      </template>
    </van-nav-bar>
    <div relative mb-5 mt-3 w-full flex justify-center>
      <img v-if="userStore.userInfo.topImagesUrl" :src="`${userStore.userInfo.topImagesUrl}`" h-15 />
      <img v-if="isVip" absolute bottom--3 w-11 src="/viptx.png" />
    </div>
    <van-field v-model="name" text-right text-xs label="昵称" placeholder="请输入最多10个字符的昵称" maxlength="10" />
    <van-cell
      title="手机号码"
      :value-class="{ e8: phone }"
       is-link h-13 items-center text-xs
      :value="phone ? hidePhoneNumber(phone) : '绑定手机号码保护账号安全'"
      @click="bindPhone()"
    />
    <van-cell
      title="更换账号"
       is-link h-13 items-center text-xs
      :value-class="{ e8: userStore.userInfo.userName }"
      :value="userStore.userInfo.userName ? userStore.userInfo.userName : '请选择您需要更换的账号'"
      @click="changeAcount()"
    />
  </div>
  <div relative bottom-30 mx-auto w-38 text-sm ct-414660>JAV旗舰欢迎您的到来！</div>
  <LoadReflesh v-model:show="showLoad" />
</template>

<style scoped>
:deep(.van-cell) {
  font-size: 16px;
  background: #111425;
  color: #e8e8e8;
  border-bottom: 1px solid #41466040;
}
:deep(.van-cell:after) {
  border: none;
}
:deep(.van-cell-group--inset) {
  border-radius: unset;
}
:deep(.van-field__label) {
  color: #e8e8e8;
}
:deep(.van-cell__value) {
  color: #5c5e69;
}

:deep(.e8) {
  color: #e8e8e8;
}
:deep(.van-field__control) {
  color: #e8e8e8;
  text-align: right;
}
:deep(.van-field__control::placeholder) {
  color: #5c5e69;
}
:deep(.van-cell__value) {
  flex: 2;
}
:deep(.van-nav-bar__content) {
  background: #111425;
}
:deep(.van-nav-bar__title) {
  color: #d0d0d0;
}
:deep(.van-nav-bar .van-icon) {
  color: #ffffff;
}
.bord {
  border-bottom: 1px solid #41466040;
}
:deep(.van-hairline--bottom:after) {
  border-bottom-width: 0;
}
</style>
